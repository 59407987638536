import axios from 'axios'
import { BaseUrl } from '../config/configUrl'
import { Key } from '../config/apikey'

const apiVersion = 'v2/'

var ServiceAuth = {

    Signup:(dataObj)=>{
        return new Promise((resolve, reject)=> {

            const headers = {
                'Content-Type':'application/json',
                'apikey': Key,
            }

            axios.post((BaseUrl + apiVersion) + 'auth/registration/',dataObj,{headers:headers})
            .then((response)=> {
                resolve({status:true,data:response})
            })
            .catch((error)=> {
                resolve({status:false,error:error})
            });
        })
    }

}
export default ServiceAuth;
