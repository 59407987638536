const dataSchema = {
  required: [],
  dataStructure: {
    id: null,
    idenity_card_number: null,
    idenity_card_back_number: null,
    idenity_card_issued_date: null,
    idenity_card_expired_date: null,
    title: null,
    first_name: null,
    last_name: null,
    first_name_en: null,
    last_name_en: null,
    phone_number: null,
    address: null,
    image: null,
    license_type: null,
    license_number: null
  },
  properties: {
    id: {
      key: 'id',
      type: 'integer',
      label: 'ID',
    },
    idenity_card_number: {
      key: 'idenity_card_number',
      type: 'string',
      label: 'หมายเลขประจำตัวประชาชน',
    },
    idenity_card_back_number: {
      key: 'idenity_card_back_number',
      type: 'string',
      label: 'หมายเลขหลังประจำตัวประชาชน',
    },
    idenity_card_issued_date: {
      key: 'idenity_card_issued_date',
      type: 'date',
      label: 'วันที่ออกบัตร',
    },
    idenity_card_expired_date: {
      key: 'idenity_card_expired_date',
      type: 'date',
      label: 'วันที่บัตรหมดอายุ',
    },
    title: {
      key: 'title',
      type: 'string',
      label: 'คำนำหน้าชื่อ',
    },
    first_name: {
      key: 'first_name',
      type: 'string',
      label: 'ชื่อ',
    },
    last_name: {
      key: 'last_name',
      type: 'string',
      label: 'นามสกุล',
    },
    first_name_en: {
      key: 'first_name_en',
      type: 'string',
      label: 'ชื่อ (ภาษาอังกฤษ)',
    },
    last_name_en: {
      key: 'last_name_en',
      type: 'string',
      label: 'นามสกุล (ภาษาอังกฤษ)',
    },
    phone_number: {
      key: 'phone_number',
      type: 'string',
      label: 'เบอร์โทรศัพท์',
    },
    address: {
      key: 'address',
      type: 'string',
      label: 'ที่อยู่',
    },
    image: {
      key: 'image',
      type: 'image upload',
      label: 'ภาพคนขับ',
    },
    license_type: {
      key: 'license_type',
      type: 'string',
      label: 'ประเภทใบขับขี่',
      max_length: '10',
    },
    license_number: {
      key: 'license_number',
      type: 'string',
      label: 'หมายเลขใบขับขี่',
      max_length: '50',
    }
  },
}

export const schema = dataSchema
