import React, { Component } from "react"
import {
    Button
    , Modal
    , Form
    , Input
    , DatePicker
    , AutoComplete
    , Row, Col
    , Avatar
    , Upload
} from 'antd';
const { Option } = AutoComplete;

const CollectionCreateForm = Form.create({ name: 'form_in_modal' })(

    class extends React.Component {

        render() {
            const { visible, onCancel, onCreate, form, dataSource, onchangeInput, onchangeAuto, handleInputSelectImageChange, imageShow } = this.props;
            const { getFieldDecorator } = form;
            const formItemLayout = {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 8 },
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 16 },
                },
            };
            const options = dataSource.map(item => (
                <Option value={item.first_name + ' ' + item.last_name} key={item.id}>
                    <span>{item.first_name + ' ' + item.last_name}</span>
                </Option>
            ))
            const uploadButton = (
                <div>
                    <div className="ant-upload-text">Upload</div>
                </div>
            );
            return (
                <Modal
                    width={860}
                    visible={visible}
                    footer={false}
                    title="บันทึกข้อมูลรถ"
                    onCancel={onCancel}
                >
                    <div style={{ marginTop: 10, marginBottom: 10 }}>
                        <Form {...formItemLayout}>
                            <Form.Item label="เบอร์รถ">
                                {getFieldDecorator('bus_number')(<Input style={{ width: 300 }} placeholder="เบอร์รถ" type="text" onChange={(event) => { onchangeInput(event, "bus_number") }} />)}
                            </Form.Item>
                            <Form.Item label="ป้ายทะเบียน">
                                {getFieldDecorator('license_plate')(<Input style={{ width: 300 }} placeholder="ป้ายทะเบียน" type="text" onChange={(event) => { onchangeInput(event, "license_plate") }} />)}
                            </Form.Item>
                            <Form.Item label="คนขับรถ">
                                {getFieldDecorator('driver',)(<AutoComplete
                                    style={{ width: 300 }}
                                    dataSource={options}
                                    placeholder="คนขับรถ"
                                    optionLabelProp="value"
                                    onChange={(event, options) => { onchangeAuto(event, "driver", options.key) }}
                                />)}
                            </Form.Item>
                            <Form.Item label="รูปรถ">
                                <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    accept="image/*"
                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    onChange={e => { handleInputSelectImageChange(e, "image") }}
                                >
                                    {imageShow ? <img src={imageShow} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                </Upload>
                            </Form.Item>
                        </Form>
                        <Row gutter={32} style={{ marginTop: 15, marginBottom: 15 }}>
                            <Col style={{ textAlign: 'right' }} xs={{ span: 16, offset: 4 }} >
                                <div style={{ marginRight: 20 }}>
                                    <Button style={{ width: 100 }} type="danger" onClick={onCancel} >ยกเลิก</Button>
                                    {'  '}
                                    <Button style={{ width: 100 }} onClick={onCreate} type="primary">บันทึก</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Modal>
            );
        }
    },
);

class ModalAdd extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            dataFromParent: props.dataFromParent,
            visible: false,
            dataInForm: {},
            imageShow: null
        }
    }

    showModal = () => {
        this.setState({ visible: true });
    };

    handleCancel = () => {
        this.setState({ visible: false });
    };

    handleCreate = () => {
            this.props.callback(this.state.dataInForm)
    };

    saveFormRef = formRef => {
        this.formRef = formRef;
    };

    onChangeDate = (dateString, key) => {
        let dataObj = this.state.dataInForm
        let date = new Date(dateString);
        let dateStr = date.toISOString()
        dataObj[key] = dateStr
        this.setState({ dataInForm: dataObj })
    }

    onchangeInput = (_event, _key) => {
        let data = this.state.dataInForm
        data[_key] = (_event.target.value)
        this.setState({ dataInForm: data })
        // console.log(this.state.dataInForm)
    }

    onchangeAuto = (_event, _key, _id) => {
        let data = this.state.dataInForm
        data[_key] = (_id)
        this.setState({ dataInForm: data })
        // console.log(this.state.dataInForm)
    }

    handleInputSelectImageChange = (event, key) => {
        let dataObj = this.state.dataInForm
        // if (info.file.status === 'uploading') {
            // this.setState({ loading: true });
        //     return;
        //   }
          if (event.file.status === 'done') {
            // Get this url from response in real world.
            this.getBase64(event.file.originFileObj, imageUrl => {
                dataObj[key] = imageUrl
                
                this.setState({ imageShow: imageUrl, dataInForm: dataObj })
            }
            );
          }
           
        

    }

    getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
      }

    render() {
        return (
            <div>
                <Button type="primary" style={{ float: "right" }} onClick={this.showModal}>
                    เพิ่มรายการ
        </Button>
                <CollectionCreateForm
                    wrappedComponentRef={this.saveFormRef}
                    visible={this.state.visible}
                    onCancel={this.handleCancel}
                    onCreate={this.handleCreate}
                    dataSource={this.props.dataFromParent}
                    onChangeDate={this.onChangeDate}
                    onchangeInput={this.onchangeInput}
                    onchangeAuto={this.onchangeAuto}
                    handleInputSelectImageChange={this.handleInputSelectImageChange}
                    imageShow={this.state.imageShow}
                />
            </div>
        );
    }
}
export default ModalAdd;
