import React, { Component } from "react"
import { connect } from 'react-redux'
import { Row, Col } from 'antd'
import { Spin } from 'antd'
import { Button, Icon } from 'antd'
import { isEmpty } from '../../lib/utils'
import Session, { getItem } from '../../lib/session'
import ChartPie from '../../components/chartPie'

import service from '../../services/trangroup'

const COLORS = ['#fa990e', '#054982'];
const thai_months = ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน',
  'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม']
class DashboardSUT extends Component {

  constructor(props) {
    super(props)
    this.state = {
      objectlist: [],
      limit: 20,
      offset: 0,
      totalSize: 0,
      sizePerPage: 10,
      page: 1,
      loading: true,
      totalTran: 0,
      objectlist2: [],
      totalSize2: [],
      chartData: [],
      now: "",
      data: [{
        section: 1,
        total: 0
      },
      {
        section: 2,
        total: 0
      },
      {
        section: 3,
        total: 0
      },
      {
        section: 4,
        total: 0
      },
      {
        section: 5,
        total: 0
      }
      ]
    }
  }

  componentDidMount() {
    if (isEmpty(getItem(Session.header))) {
      this.props.history.push('/login')
    } else {
      setTimeout(() => {
        if (this.props.auth.token !== null) {
          this.init_data(this.props.auth.token)
          this.getTime()
        }
      }, 800)
    }

  }

  init_data = (_token) => {


    this.spinLoading(true)
    this.init_data_option(this.props.auth.token).then((res, err) => {
      if (res.state) {
        this.setState({
          data: res.data.data.results,
        })
        this.spinLoading(false)
      } else {
        console.log("get data error !!")
      }

    })

  }


  init_data_option = (_token, _option) => {
    return new Promise((resolve, reject) => {
      service.getList(_token, _option).then((res, err) => {
        if (res.status) {
          if (res.data.status === 200) {
            resolve({ state: true, data: res.data })
          }
        } else {

        }

      })
    })
  }


  spinLoading = (_bool) => {
    this.setState({ loading: _bool })
  }

  getTime = () => {
    let tmp_date = new Date();
    let curr_date = tmp_date.getDate() + '/' + (tmp_date.getMonth() + 1) + '/' + tmp_date.getFullYear()
      + ' ' + tmp_date.getHours() + ':' + (tmp_date.getMinutes() < 10 ? "0" + tmp_date.getMinutes() : tmp_date.getMinutes());

    this.setState({ now: curr_date });
  }

  findTotal = (id) => {
    if (this.state.data.length > 0) {
        let obj = this.state.data.find(x => x.section === id)
        if (obj === undefined || obj === null) {
            return 0
        } else {
            return obj.total
        }
    } else {
        return 0;
    }
}

  render() {
    const { loading, now, data } = this.state
    const date = new Date();
    return (
      <div style={{ marginLeft: '5%' }}>
        <Row>
          <Col span={6}>
            <div style={{ backgroundColor: "#a2de96", width: "90%", height: 150, color: "white", paddingRight: 20 }}>
              <div style={{ fontSize: 18, paddingTop: 20, textAlign: "right" }}>
                จำนวนการแตะบัตรของนักศึกษาปีที่ 1
                    </div>
              <div style={{ fontSize: 11, textAlign: "right" }}>ประจำเดือน {thai_months[date.getMonth()]}</div>
              <div style={{ fontSize: 32, paddingTop: 5, textAlign: "right" }}>
                {this.findTotal(1)}
              </div>
            </div>
          </Col>
          <Col span={6}>
            <div style={{ backgroundColor: "#e79c2a", width: "90%", height: 150, color: "white", paddingRight: 20 }}>
              <div style={{ fontSize: 18, paddingTop: 20, textAlign: "right" }}>
                จำนวนการแตะบัตรของนักศึกษาปีที่ 2
                    </div>
              <div style={{ fontSize: 11, textAlign: "right" }}>ประจำเดือน {thai_months[date.getMonth()]}</div>
              <div style={{ fontSize: 32, paddingTop: 5, textAlign: "right" }}>
              {this.findTotal(2)}
              </div>
            </div>
          </Col>
          <Col span={9}>
            <div style={{ backgroundColor: "#823066", width: "90%", height: 150, color: "white" }}>
              <Row>
                <Col span={8}>
                  <Icon type="car" style={{ fontSize: '60px', paddingTop: '40%', paddingLeft: 30 }} theme="outlined" />
                </Col>
                <Col span={16}>
                  <div style={{ fontSize: 18, paddingRight: 20, paddingTop: 20, textAlign: "right" }}>
                    จำนวนรถที่ให้บริการ
                    </div>
                  <div style={{ fontSize: 11, textAlign: "right", paddingRight: 20 }}>(ณ {now} น.)</div>
                  <div style={{ fontSize: 32, paddingRight: 20, paddingTop: 5, textAlign: "right" }}>
                    8
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Col span={6}>
            <div style={{ backgroundColor: "#3ca59d", width: "90%", height: 150, color: "white", paddingRight: 20 }}>
              <div style={{ fontSize: 18, paddingTop: 20, textAlign: "right" }}>
                จำนวนการแตะบัตรของนักศึกษาปีที่ 3
                    </div>
              <div style={{ fontSize: 11, textAlign: "right" }}>ประจำเดือน {thai_months[date.getMonth()]}</div>
              <div style={{ fontSize: 32, paddingTop: 5, textAlign: "right" }}>
              {this.findTotal(3)}
              </div>
            </div>
          </Col>
          <Col span={6}>
            <div style={{ backgroundColor: "#5a3d55", width: "90%", height: 150, color: "white", paddingRight: 20 }}>
              <div style={{ fontSize: 18, paddingTop: 20, textAlign: "right" }}>
                จำนวนการแตะบัตรของนักศึกษาปีที่ 4
                    </div>
              <div style={{ fontSize: 11, textAlign: "right" }}>ประจำเดือน {thai_months[date.getMonth()]}</div>
              <div style={{ fontSize: 32, paddingTop: 5, textAlign: "right" }}>
              {this.findTotal(4)}
              </div>
            </div>
          </Col>
          <Col span={6}>
            <div style={{ backgroundColor: "#ff9234", width: "90%", height: 150, color: "white", paddingRight: 20 }}>
              <div style={{ fontSize: 18, paddingTop: 20, textAlign: "right" }}>
                จำนวนการแตะบัตรของบุคลากรและอื่นๆ
                    </div>
              <div style={{ fontSize: 11, textAlign: "right" }}>ประจำเดือน {thai_months[date.getMonth()]}</div>
              <div style={{ fontSize: 32, paddingTop: 5, textAlign: "right" }}>
              {this.findTotal(5)}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { auth: state.auth }
}

export default connect(mapStateToProps)(DashboardSUT);