import axios from 'axios'
import { BaseUrl } from '../config/configUrl'
import { Key } from '../config/apikey'

const apiVersion = 'v2/'

var ServiceBus = {

   getList:(_token)=>{
        const headers = {
            'Content-Type':'application/json',
            'Authorization':('Token '+_token),
            'apikey':Key,
        }

        return new Promise((resolve, reject)=> {
            axios.get((BaseUrl +apiVersion)+'buses/',{headers:headers})
            .then((response)=> {
                resolve({status:true,data:response})
            })
            .catch((error)=> {
                resolve({status:false,error:error})
            });
        })
   },

   getListOption: ( _token , _option ) => {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': ( 'Token ' + _token ),
      'apikey': Key
    }

    return new Promise( ( resolve, reject ) => {
      axios.get( ( BaseUrl  + apiVersion ) + 'buses/?limit='+_option.limit+'&offset='+_option.offset+_option.filter_string, { headers: headers } ).then( ( response ) => {
        resolve( { status: true, data: response, } )
      } ).catch( ( error ) => {
        resolve( { status: false, error: error, } )
      } );
    } )

  },

   getListBusLimit:(_token,_offset,_limit)=>{
        const headers = {
            'Content-Type':'application/json',
            'Authorization':('Token '+_token),
            'apikey':Key,
        }

        return new Promise((resolve, reject)=> {
            axios.get((BaseUrl +apiVersion)+'buses/?limit='+_limit+'&offset='+_offset,{headers:headers})
            .then((response)=> {
                resolve({status:true,data:response})
            })
            .catch((error)=> {
                resolve({status:false,error:error})
            });
        })
    },

   AddBus:(_token,data)=>{

        // delete data.id
        // console.log("AddBus => ",data)
        const headers = {
            'Content-Type':'application/json',
            'Authorization':('Token '+_token),
            'apikey':Key
        }

        return new Promise((resolve, reject)=> {
            axios.post((BaseUrl +apiVersion)+'buses/',data,{headers:headers})
            .then((response)=> {
                resolve({status:true,data:response})
            })
            .catch((error)=> {
                resolve({status:false,error:error})
            });
        })

    },

    FullUpdateBus:(_token,data)=>{
        const id = data.id
        delete data.id

        const headers = {
            'Content-Type':'application/json',
            'Authorization':('Token '+_token),
            'apikey':Key
        }

        return new Promise((resolve, reject)=> {
            axios.patch((BaseUrl +apiVersion)+'buses/'+id+'/',data,{headers:headers})
            .then((response)=> {
                resolve({status:true,data:response})
            })
            .catch((error)=> {
                resolve({status:false,error:error})
            });
        })

    },

    DeleteBus:(_token,_id)=>{

        const headers = {
            'Content-Type':'application/json',
            'Authorization':('Token '+_token),
            'apikey':Key
        }

        return new Promise((resolve, reject)=> {
            axios.delete((BaseUrl +apiVersion)+'buses/'+_id+'/',{headers:headers})
            .then((response)=> {
                resolve({status:true,data:response})
            })
            .catch((error)=> {
                resolve({status:false,error:error})
            });
        })

    }



}
export default ServiceBus;
