import React, { Component } from 'react'
import { DatePicker, Button } from 'antd';
const { RangePicker } = DatePicker;

class DateRange extends Component {
    constructor(props){
        super(props)
        this.state = {
            dateforfitler: {}
        }
    }

    onChange = (date, dateString) => {
        let date1 = this.state.dateforfitler
        date1["start_date"] = dateString[0]
        date1["end_date"] = dateString[1]
        this.setState({dateforfitler:date1});
      }

    handleSearch = () => {
        setTimeout(()=>{
          this.props.callback(this.state.dateforfitler)
        },1000)
      };
      
    render(){
        return (
            <div>
            <RangePicker placeholder={['วันที่เริ่มต้น', 'วันที่สิ้นสุด']} onChange={ this.onChange } separator="ถึง" format="YYYY-MM-DD" />
            <Button type="primary" icon="search" onClick={this.handleSearch} />
            </div>
        );
    }
}
export default DateRange;