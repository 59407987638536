import React, { Component } from "react"
import { connect } from 'react-redux'
import { Row, Col, message } from 'antd'
import { Button,Spin } from 'antd'
import DataGrid from '../../components/dataGrid'
import FormModal from '../../components/formModal'
import { isEmpty } from '../../lib/utils'
import Session, { getItem } from '../../lib/session'
import service from '../../services/fuels'
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import { schema } from '../../formSchema/fuel/fuelSchema'
import { BaseMediaUrl } from '../../config/configUrl'
import DateRange from '../../components/dateRange'
import ModalAdd from '../../formSchema/fuel/fuelAddModal'
import servicebus from '../../services/bus'

function dateFormatter(cell, row) {

  return (
    <span> {  new Date(cell).toLocaleDateString('en-GB') } </span>
  );
}

const columns = [
    {
      dataField: 'bus',
      text: 'Bus id',
      sort: true,
      filter: textFilter({
        style: {width: 100}
      }),
      
    },{
      dataField: 'bus_name',
      text: 'Bus Name',
      sort: true,
      filter: textFilter({
        style: {width: 100}
      }),
    },{
      dataField: 'fuel_date',
      text: 'วันที่เติม',
      sort: true,
      formatter: dateFormatter,
    }, {
      dataField: 'mileage_previous',
      text: 'เลขไมล์ก่อนหน้า',
      sort: true,
    },{
      dataField: 'mileage_current',
      text: 'เลขไมล์ปัจจุบัน',
      sort: true,
    }, {
      dataField: 'mileage',
      text: 'ความต่างเลขไมล์',
      sort: true,
    }, {
        dataField: 'gas_volume',
        text: 'ปริมาณแก๊สที่เติม',
        sort: true,
      }, {
        dataField: 'gas_money',
        text: 'ราคาแก๊สที่เติม',
        sort: true,
      }, {
        dataField: 'gas_volume_vga',
        text: 'อัตราการสิ้นเปลือง',
        sort: true,
      }, {
        dataField: 'gas_mileage_vga',
        text: 'ระยะทาง',
        sort: true,
      }, {
        dataField: 'gas_full_unit_price',
        text: 'ราคาเต็ม',
        sort: true,
      }, {
        dataField: 'gas_discount_unit_price',
        text: 'ส่วนลด',
        sort: true,
      }, {
        dataField: 'gas_unit_price_difference',
        text: 'ผลต่าง',
        sort: true,
      }, {
        dataField: 'gas_total_price',
        text: 'รวมราคาเต็ม',
        sort: true,
      }, {
        dataField: 'gas_total_paid',
        text: 'รวมราคาจ่าย',
        sort: true,
      }, {
        dataField: 'gas_total_discount',
        text: 'รวมส่วนลด',
        sort: true,
      }, {
        dataField: 'service_station',
        text: 'สถานีบริการ',
        sort: true,
      },
  ];

  class Fuel extends Component {

    constructor( props ) {
      super( props )
      this.state = {
        objectlist: [],
        limit: 20,
        offset:0,
        filter_string: "",
        totalSize:0,
        sizePerPage:20,
        page:1,
        formAllState: {
          Create: "Create",
          Update: "Update",
          Delete: "Delete",
          Show: "Show",
        },
        loading: false,
        busnumber: [],
      }
    }

    componentDidMount() {
      if ( isEmpty( getItem( Session.header ) ) ) {
        this.props.history.push( '/login' )
      } else {
        setTimeout(()=>{
          if(this.props.auth.token !== null){
              this.init_data(this.props.auth.token)
              this.bus_data(this.props.auth.token)
          }
        },800)

      }

    }

    spinLoading = (_bool) => {
      this.setState({loading:_bool})
    }

      init_data = (_token) => {

        let obj = {
          limit: this.state.limit,
          offset: this.state.offset,
          filter_string: this.state.filter_string
        }
        this.spinLoading(true)
        this.init_data_option(this.props.auth.token,obj).then((res,err) => {

            if(res.state){
              this.setState({
                objectlist: res.data.data.results,
                totalSize:res.data.data.count
              })
              this.spinLoading(false)
            }else{
              console.log("get data error !!")
            }

        })

    }

    init_data_option = (_token,_option) => {
      return new Promise( ( resolve, reject ) => {
        service.getListOption(_token,_option).then((res,err)=> {

          if (res.status){
            if(res.data.status === 200){
              resolve({state:true,data:res.data})
            }
          }else{
            if(res.error.response.status === 401){
                this.props.history.push( '/login' )
            }
          }

        })
      })
    }

    bus_data = (_token) => {
      this.get_bus_data(_token).then((res,err) => {
        if(res.status === 200){
          this.setState({ busnumber: res.data.results})
        }else{
          console.log("get data error !!")
        }
      })
    }
  
    get_bus_data = (_token) => {
      return new Promise( ( resolve, reject ) => {
        servicebus.getList(_token).then((res,err )=> {
          if (res.data != null){
            if(res.data.status === 200){
              resolve(res.data)
            }
          }
        })
      })
    }

    funcAdd = ( _dataObj ) => {
      service.AddFuels( this.props.auth.token, _dataObj ).then( ( res, err ) => {
          console.log("res => ",res)
          if(res.status){
            console.log("success")
            message.success('เพิ่มข้อมูลสำเร็จ');
           
        }else{
            console.log("error")
            message.error('เกิดข้อผิดพลาดในการเพิ่มข้อมูล กรุณาลองใหม่อีกครั้งค่ะ');
        }
      } )
    }

    funcFullUpdate = ( _dataObj ) => {
      console.log("_dataObj => ",_dataObj)
      service.FullUpdateFuels( this.props.auth.token, _dataObj ).then( ( res, err ) => {
          console.log("res => ",res)
          if(res.status){
            console.log("success")
            message.success('แก้ไขข้อมูลสำเร็จ');
            this.myChildFormModal.handleCancel()
        }else{
            console.log("error")
            message.error('เกิดข้อผิดพลาดในการแก้ไขข้อมูล กรุณาลองใหม่อีกครั้งค่ะ');
        }
      } )
    }

    funcCallbackDelete = ( _id ) => {
      // console.log("funcCallbackDelete on id => ",_id)
      service.DeleteFuels( this.props.auth.token, _id ).then( ( res, err ) => {
        console.log( "res => ", res )
        if(res.status){
          console.log("success")
          message.success('ลบข้อมูลสำเร็จ');
          this.myChildFormModal.handleCancel()
      }else{
          console.log("error")
          message.error('เกิดข้อผิดพลาดในการลบข้อมูล กรุณาลองใหม่อีกครั้งค่ะ');
      }
      } )
    }

    formatData = ( _dataObj, _row ) => {

      return new Promise( ( resolve, reject ) => {
        let results = {}
        Object.keys( _dataObj ).forEach( ( row, index ) => {
  
          results[ row ] = _row[ row ]
  
          if ( ( Object.keys( _dataObj ).length - 1 ) === index ) {
            resolve( results )
          }
  
        } );
      } )
  
    }

    DataGridCallback  = ( _row, _index ) => {
      // console.log("DataGridCallback => ",_row)
      // console.log("DataGridCallback index  => ",_index)
      this.formatData( schema.dataStructure, _row ).then( ( res, err ) => {
  
        let dataForChild = {
          dataObj: {
            results: res,
            dataDefault: res,
            schema: schema,
            idx: _index,
            option: this.state.busnumber
          },
          formObj: {
            formState: this.state.formAllState.Update,
            formAllState: this.state.formAllState,
          },
        }
  
        this.myChildFormModal.initFormModal( dataForChild )
      } )
  
    }

    handleClickAdd = () => {

      let dataForChild = {
        dataObj: {
          results: schema.dataStructure,
          dataDefault: schema,
          schema: schema,
          idx: null,
          selectoption: null,
        },
        formObj: {
          formState: this.state.formAllState.Create,
          formAllState: this.state.formAllState,
        },
      }
  
      this.myChildForAdd.initFormModal( dataForChild )
    }

    funcCallBackModalAdd = ( _dataCallBack ) => {
      this.funcAdd( _dataCallBack )
      // console.log(_dataCallBack)
    }

    funcCallBackModalUpdate = (_dataCallBack) => {
      this.funcFullUpdate( _dataCallBack )
      // console.log(_dataCallBack)
    }
  
    modalMessageCallback = () => {
      console.log("modalMessageCallback")
      this.myChildForAdd.handleCancel()
      this.myChildFormModal.handleCancel()
    }

    PaginationCallback  = ( type , _limit, _offset , _filter_string , _page) => {

      let obj = {
        limit: _limit,
        offset: _offset,
        filter_string: _filter_string,
        loading:false
      }
  
      if(type === 'filter' || type === 'pagination' || type === 'sort'  ){
        this.spinLoading(true)
          this.init_data_option(this.props.auth.token,obj).then((res,err)=>{
  
            if(res.state){
              this.setState({
                limit: _limit,
                offset: _offset,
                page: _page,
                objectlist: res.data.data.results,
                idx: null,
                totalSize: res.data.data.count,
              })
              this.spinLoading(false)
            }else{
              console.log("get data error !!")
            }
  
          })
      }
  
    }

    render() {

      const { objectlist , totalSize , page , limit, loading} = this.state
  
      return (
        <Spin spinning={loading} delay={300} tip="Loading...">
        <div>
          <Row gutter={16}>
            <Col span={24}>
              <h2>รายงานการเติมเชื้อเพลิง</h2>
            </Col>
          </Row>
          <br/>
          <Row gutter={16}>
            <Col span={24}>
            <ModalAdd dataFromParent={this.state.busnumber} callback={this.funcCallBackModalAdd}/>
            </Col>
          </Row>
          <br/>
          <Row gutter={16}>
            <Col span={24}>
            {
            (
              objectlist.length === 0 ?
              null
              :
              <div>
                <FormModal callback={this.funcCallBackModalUpdate} callbackDel={this.funcCallbackDelete} title="แก้ไขจุดจอด" onRef={refs => ( this.myChildFormModal = refs )}/>
              </div>
            )
          }
                <DateRange />
                <DataGrid callback={this.DataGridCallback} totalSize={totalSize} page={page} limit={limit} paginationCallback={this.PaginationCallback} columns={columns} dataList={objectlist} />
            </Col>
          </Row>
        </div>
        </Spin>
      );
    }
  
}

const mapStateToProps = state => {
return { auth: state.auth ,test: state.Fuel,}
}

export default connect( mapStateToProps )( Fuel );