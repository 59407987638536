import React, { Component } from "react"
import { connect } from 'react-redux'
import { Row, Col, Spin, message} from 'antd'
import { Button } from 'antd'
import DataGrid from '../../components/dataGrid'
import FormModal from '../../components/formModal'
import { isEmpty } from '../../lib/utils'
import Session, { getItem } from '../../lib/session'
import service from '../../services/line'
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import ModalAlertMessage from '../../components/modalAlertMessage'
import './line.css'

import { schema } from '../../formSchema/line/lineSchema'
import action from '../../redux/line/linesTable/linesRedux'
import { BaseMediaUrl } from '../../config/configUrl'


function imageFormatter( cell, row ) {
  return ( <img src={BaseMediaUrl + cell} className="datagrid-image"/> );
}

const columns = [
  {
    dataField: 'name',
    text: 'ชื่อสาย',
    sort: true,
    filter: textFilter(),
  }, {
    dataField: 'description',
    text: 'รายละเอียด',
    sort: true,
  }, {
    dataField: 'color',
    text: 'โค้ดสี',
    sort: true,
    filter: textFilter(),
  }, {
    dataField: 'text_color',
    text: 'ชื่อสี',
    sort: true,
    filter: textFilter(),
  }
];

class Line extends Component {

  constructor( props ) {
    super( props )
    this.state = {
      objectlist: [],
      limit: 10,
      offset:0,
      filter_string: "",
      totalSize:0,
      sizePerPage:10,
      page:1,
      loading: false,
      formAllState: {
        Create: "Create",
        Update: "Update",
        Delete: "Delete",
        Show: "Show",
      },
    }
  }

  componentDidMount() {
    if ( isEmpty( getItem( Session.header ) ) ) {
    this.props.history.push( '/login' )
    } else {
      setTimeout(()=>{
        if(this.props.auth.token !== null){
          this.init_data(this.props.auth.token)
        }
      },800)
    }
}

spinLoading = (_bool) => {
  this.setState({loading:_bool})
}

init_data = (_token) => {
  this.spinLoading(true)
  let obj = {
    limit: this.state.limit,
    offset: this.state.offset,
    filter_string: this.state.filter_string
  }

  this.init_data_option(this.props.auth.token,obj).then((res,err) => {

      if(res.status === 200){
        this.setState({
          objectlist: res.data.results,
          totalSize: res.data.count
        })
        this.spinLoading(false)
      }else{
        console.log("get data error !!")
      }
  })
}

init_data_option = (_token,_option) => {
  return new Promise( ( resolve, reject ) => {
    service.getListOption(_token,_option).then((res,err )=> {
      if (res.status){
        if(res.data.status === 200){
          resolve(res.data)
        }
      }else{
        if(res.error.response.status === 401){
            this.props.history.push( '/login' )
        }
      }
    })
  })
}

  formatData = ( _dataObj, _row ) => {

    return new Promise( ( resolve, reject ) => {
      let results = {}
      Object.keys( _dataObj ).forEach( ( row, index ) => {

        results[ row ] = _row[ row ]

        if ( ( Object.keys( _dataObj ).length - 1 ) === index ) {
          resolve( results )
        }

      } );
    } )

  }

  PaginationCallback  = ( type , _limit, _offset , _filter_string , _page) => {
    // console.log("PaginationCallback => on ")
    // console.log({type , _limit, _offset , _filter_string , _page})
    this.spinLoading(true)
    let obj = {
      limit: _limit,
      offset: _offset,
      filter_string: _filter_string
    }

    if(type === 'filter' || type === 'pagination' ){
        this.init_data_option(this.props.auth.token,obj).then((res,err)=>{
          if(res.status === 200){
            this.setState({
              limit: _limit,
              offset: _offset,
              page: _page,
              objectlist: res.data.results,
              totalSize: res.data.count
            })
            this.spinLoading(false)
          }else{
            console.log("get data error !!")
          }

        })
    }

  }

  DataGridCallback = ( _row, _index ) => {

    this.formatData( schema.dataStructure, _row ).then( ( res, err ) => {

      let dataForChild = {
        dataObj: {
          results: res,
          dataDefault: res,
          schema: schema,
          idx: _index,
        },
        formObj: {
          formState: this.state.formAllState.Update,
          formAllState: this.state.formAllState,
        },
      }

      this.myChildFormModal.initFormModal( dataForChild )
    } )

  }


  funcCallbackDelete = ( _id ) => {
    service.DeleteLine( this.props.auth.token, _id ).then( ( res, err ) => {
      console.log( "res => ", res )
        if(res.status){
          console.log("success")
          message.success('ลบข้อมูลสำเร็จ');
          this.myChildFormModal.handleCancel()
        }else{
            console.log("error")
            message.error('เกิดข้อผิดพลาดในการลบข้อมูล กรุณาลองใหม่อีกครั้งค่ะ');
        }
    } )
  }

  funcFullUpdate = ( _dataObj ) => {
    service.FullUpdateLine( this.props.auth.token, _dataObj ).then( ( res, err ) => {
        console.log("res => ",res)
        if(res.status){
          console.log("success")
          message.success('แก้ไขข้อมูลสำเร็จ');
          this.myChildFormModal.handleCancel()
        }else{
          console.log("error")
          message.error('เกิดข้อผิดพลาดในการแก้ไขข้อมูล กรุณาลองใหม่อีกครั้งค่ะ');
        }
    } )
  }

  funcCallBackModalAdd = ( _dataCallBack ) => {
    this.funcAdd( _dataCallBack )
  }

  funcAdd = ( _dataObj ) => {
    // console.log("funcAdd => ",_dataObj)
    service.AddLine( this.props.auth.token, _dataObj ).then( ( res, err ) => {
        if(res.status){
          console.log("success")
          message.success('เพิ่มข้อมูลสำเร็จ');
          this.myChildForAdd.handleCancel()
        }else{
          console.log("error")
          message.error('เกิดข้อผิดพลาดในการเพิ่มข้อมูล กรุณาลองใหม่อีกครั้งค่ะ');
        }
    } )
  }

  handleClickAdd = () => {

    let dataForChild = {
      dataObj: {
        results: schema.dataStructure,
        dataDefault: schema,
        schema: schema,
        idx: null,
      },
      formObj: {
        formState: this.state.formAllState.Create,
        formAllState: this.state.formAllState,
      },
    }

    this.myChildForAdd.initFormModal( dataForChild )
  }

  funcCallBackModalUpdate = (_dataCallBack) => {
    this.funcFullUpdate( _dataCallBack )
  }

  modalMessageCallback = () => {
    console.log("modalMessageCallback")
    this.myChildForAdd.handleCancel()
    this.myChildFormModal.handleCancel()
  }

  render() {

    let { objectlist , totalSize , page , limit, loading } = this.state
    return (
    <Spin spinning={loading} delay={300} tip="Loading...">
    <div>
      <Row gutter={16}>
        <Col span={24}>
          <h2>ข้อมูลสายการเดินรถ</h2>
        </Col>
      </Row>
      <br/>
      
      <Row gutter={16}>
        <Col span={24}>
         
          <Button onClick={() => {this.handleClickAdd()}} style={{float: "right"}} type="primary">เพิ่มรายการ</Button>
        </Col>
      </Row>
      <br/>
      <Row gutter={16}>
        <Col span={24}>
          {
            (
              objectlist.length === 0
              ? null
              : 
              <div>
                <FormModal title="แก้ไขสายเดินรถ" onRef={refs => ( this.myChildFormModal = refs )} callback={this.funcCallBackModalUpdate} callbackDel={this.funcCallbackDelete}/>
                <FormModal callback={this.funcCallBackModalAdd} title="เพิ่มสายเดินรถ" onRef={refs => ( this.myChildForAdd = refs )}/>
              </div>)
          }
          <DataGrid callback={this.DataGridCallback} totalSize={totalSize} page={page} limit={limit} paginationCallback={this.PaginationCallback} columns={columns} dataList={objectlist} />
        </Col>
      </Row>
    </div>
    </Spin> );

  }

}

const mapStateToProps = state => {
  return { auth: state.auth, line: state.line, }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchData: ( token ) => {
      dispatch( action.fetchLinesData( token ) )
    },
    updateData: (dataObj) => {
      console.log("")
      // console.log("updateBusData => ",dataObj)
      // console.log("busAction =>",busAction.fetchBusSuccess(dataObj))
      // dispatch(busAction.fetchBusSuccess(dataObj))
    }
  }
}

export default connect( mapStateToProps, mapDispatchToProps )( Line );
