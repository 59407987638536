
const dataSchema = {
    required:[],
    dataStructure:{
        id:null,
        bus_number: null,
        license_plate: null,
        driver: null,
        image: null
    },
    properties:{
            id: {
                key:"id",
                type: "integer",
                label: "ID"
            },
            bus_number: {
                key:"bus_number",
                type: "string",
                label: "เบอร์รถ",
                max_length: 50
            },
            license_plate: {
                key:"license_plate",
                type: "string",
                label: "ป้ายทะเบียนรถ",
                max_length: 50
            },
            driver: {
                key:"driver",
                type: "autocomplete",
                label: "คนขับรถ"
            },
            image: {
                key:"image",
                type: "image upload",
                label: "Image"
            }
    }
}


export const schema = dataSchema