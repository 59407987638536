import axios from 'axios'
import { BaseUrl } from '../config/configUrl'
import { Key } from '../config/apikey'

const apiVersion = 'v2/'

var Service = {

  getList: (_token) => {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': ('Token ' + _token),
      'apikey': Key,
    }

    return new Promise((resolve, reject) => {
      axios.get((BaseUrl + apiVersion) + 'timetable-line/', { headers: headers })
        .then((response) => {
          resolve({ status: true, data: response })
        })
        .catch((error) => {
          resolve({ status: false, error: error })
        });
    })
  },
  getListOption: (_token, _option) => {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': ('Token ' + _token),
      'apikey': Key
    }
    return new Promise((resolve, reject) => {
      axios.get((BaseUrl + apiVersion) + 'timetable-line/?limit=' + _option.limit + '&offset=' + _option.offset + _option.filter_string, { headers: headers }).then((response) => {
        resolve({ status: true, data: response, })
      }).catch((error) => {
        resolve({ status: false, error: error, })
      });
    })

  },
  AddTime:(_token,data)=>{

    // delete data.id
    // console.log("AddBus => ",data)
    const headers = {
        'Content-Type':'application/json',
        'Authorization':('Token '+_token),
        'apikey':Key
    }

    return new Promise((resolve, reject)=> {
        axios.post((BaseUrl +apiVersion)+'timetable-line/',data,{headers:headers})
        .then((response)=> {
            resolve({status:true,data:response})
        })
        .catch((error)=> {
            resolve({status:false,error:error})
        });
    })

},

FullUpdateTime:(_token,data)=>{
    const id = data.id
    const headers = {
        'Content-Type':'application/json',
        'Authorization':('Token '+_token),
        'apikey':Key
    }

    return new Promise((resolve, reject)=> {
        axios.put((BaseUrl +apiVersion)+'timetable-line/'+id+'/',data,{headers:headers})
        .then((response)=> {
            resolve({status:true,data:response})
        })
        .catch((error)=> {
            resolve({status:false,error:error})
        });
    })

},

DeleteTime:(_token,_id)=>{

    const headers = {
        'Content-Type':'application/json',
        'Authorization':('Token '+_token),
        'apikey':Key
    }

    return new Promise((resolve, reject)=> {
        axios.delete((BaseUrl +apiVersion)+'timetable-line/'+_id+'/',{headers:headers})
        .then((response)=> {
            resolve({status:true,data:response})
        })
        .catch((error)=> {
            resolve({status:false,error:error})
        });
    })

}



}
export default Service;
