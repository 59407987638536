import React, { Component } from "react"
import { connect } from 'react-redux'
import { Row, Col, } from 'antd'
import { Spin, Icon } from 'antd'
import { Button, message } from 'antd'
import DataGrid from '../../components/dataGrid'
import FormModal from '../../components/formModal'
import { isEmpty } from '../../lib/utils'
import Session, { getItem } from '../../lib/session'
import filterFactory, { textFilter, dateFilter, } from 'react-bootstrap-table2-filter'
import service from '../../services/linesetting'
import { schema } from '../../formSchema/lineSetting/linesettingSchema'
import { BaseMediaUrl } from '../../config/configUrl'
import ModalAdd from '../../formSchema/lineSetting/lineSettingAdd'
import servicebus from '../../services/bus'
import serviceline from '../../services/line'

function dateFormatter(cell, row) {

	return (
		<span> {new Date(cell).toLocaleDateString('en-GB') + ' ' + new Date(cell).toLocaleTimeString('en-GB')} </span>
	);
}


const columns = [
	{
		dataField: 'line_name',
		text: 'สายเดินรถ',
		sort: true,
	}, {
		dataField: 'bus_name',
		text: 'เบอร์รถ',
		sort: true,
	}, {
		dataField: 'start_time',
		text: 'เวลาที่เริ่มวิ่ง',
	}, {
		dataField: 'end_time',
		text: 'เวลาที่หยุดวิ่ง',
	}
];

class LineSetting extends Component {

	constructor(props) {
		super(props)
		this.state = {
			objectlist: [],
			limit: 20,
			offset: 0,
			filter_string: "",
			totalSize: 0,
			sizePerPage: 20,
			page: 1,
			loading: true,
			formAllState: {
				Create: "Create",
				Update: "Update",
				Delete: "Delete",
				Show: "Show",
			},
			bus: [],
			line: []
		}
	}

	componentDidMount() {
		if (isEmpty(getItem(Session.header))) {
			this.props.history.push('/login')
		} else {
			setTimeout(() => {
				if (this.props.auth.token !== null) {
					this.init_data(this.props.auth.token)
					this.get_bus_data(this.props.auth.token)
					this.get_line_data(this.props.auth.token)
				}
			}, 800)

		}

	}

	init_data = (_token) => {

		let obj = {
			limit: this.state.limit,
			offset: this.state.offset,
			filter_string: this.state.filter_string
		}
		this.spinLoading(true)
		this.init_data_option(this.props.auth.token, obj).then((res, err) => {

			if (res.state) {
				this.setState({
					objectlist: res.data.data.results,
					totalSize: res.data.data.count
				})
				this.spinLoading(false)
			} else {
				console.log("get data error !!")
			}

		})

	}


	init_data_option = (_token, _option) => {
		return new Promise((resolve, reject) => {
			service.getListOption(_token, _option).then((res, err) => {

				if (res.status) {
					if (res.data.status === 200) {
						resolve({ state: true, data: res.data })
					}
				} else {
					if (res.error.response.status === 401) {
						this.props.history.push('/login')
					}
				}

			})
		})
	}

	get_bus_data = (_token) => {
		servicebus.getList(_token).then((res, err) => {
			if (res.data != null) {
				if (res.data.status === 200) {
					this.setState({ bus: res.data.data.results })
				}
			}
		})
	}

	get_line_data = (_token) => {
		serviceline.getListLine(_token).then((res, err) => {
			if (res.data != null) {
				if (res.data.status === 200) {
					this.setState({ line: res.data.data.results })
				}
			}
		})
	}

	funcAdd = (_dataObj) => {
		service.AddTime(this.props.auth.token, _dataObj).then((res, err) => {
			console.log("res => ", res)
			if (res.status) {
				console.log("success")
				message.success('เพิ่มข้อมูลสำเร็จ');
				// this.myChildForAdd.handleCancel()

			} else {
				console.log("error")
				message.error('เกิดข้อผิดพลาดในการเพิ่มข้อมูล กรุณาลองใหม่อีกครั้งค่ะ');
			}
		})
	}

	funcFullUpdate = (_dataObj) => {
		service.FullUpdateTime(this.props.auth.token, _dataObj).then((res, err) => {
			console.log("res => ", res)
			if (res.status) {
				console.log("success")
				message.success('แก้ไขข้อมูลสำเร็จ');
				this.myChildFormModal.handleCancel()
			} else {
				console.log("error")
				message.error('เกิดข้อผิดพลาดในการแก้ไขข้อมูล กรุณาลองใหม่อีกครั้งค่ะ');
			}
		})
	}

	funcCallbackDelete = (_id) => {
		// console.log("funcCallbackDelete on id => ",_id)
		service.DeleteTime(this.props.auth.token, _id).then((res, err) => {
			console.log("res => ", res)
			if (res.status) {
				message.success('ลบข้อมูลสำเร็จ');
				this.myChildFormModal.handleCancel()
			} else {
				message.error('เกิดข้อผิดพลาดในการลบข้อมูล กรุณาลองใหม่อีกครั้งค่ะ');
			}
		})
	}

	handleClickAdd = () => {

		let dataForChild = {
			dataObj: {
				results: schema.dataStructure,
				dataDefault: schema,
				schema: schema,
				idx: null,
			},
			formObj: {
				formState: this.state.formAllState.Create,
				formAllState: this.state.formAllState,
			},
		}

		this.myChildForAdd.initFormModal(dataForChild)
	}

	funcCallBackModalAdd = (_dataCallBack) => {
		this.funcAdd(_dataCallBack)
	}

	funcCallBackModalUpdate = (_dataCallBack) => {
		console.log(_dataCallBack)
		this.funcFullUpdate(_dataCallBack)
	}

	modalMessageCallback = () => {
		console.log("modalMessageCallback")
		this.myChildForAdd.handleCancel()
		this.myChildFormModal.handleCancel()
	}


	DataGridCallback = (_row, _index) => {

		this.formatData(schema.dataStructure, _row).then((res, err) => {

			let dataForChild = {
				dataObj: {
					results: res,
					dataDefault: res,
					schema: schema,
					idx: _index,
					canDelete: true,
					option: this.state.line
				},
				formObj: {
					formState: this.state.formAllState.Update,
					formAllState: this.state.formAllState,
				},
			}

			this.myChildFormModal.initFormModal(dataForChild)
		})

	}

	PaginationCallback = (type, _limit, _offset, _filter_string, _page) => {

		let obj = {
			limit: _limit,
			offset: _offset,
			filter_string: _filter_string,
			loading: false
		}

		if (type === 'filter' || type === 'pagination' || type === 'sort') {
			this.spinLoading(true)
			this.init_data_option(this.props.auth.token, obj).then((res, err) => {
				if (res.state) {
					this.setState({
						limit: _limit,
						offset: _offset,
						page: _page,
						objectlist: res.data.data.results
					})
					this.spinLoading(false)
				} else {
					console.log("get data error !!")
				}

			})
		}

	}

	spinLoading = (_bool) => {
		this.setState({ loading: _bool })
	}
	formatData = (_dataObj, _row) => {

		return new Promise((resolve, reject) => {
			let results = {}
			Object.keys(_dataObj).forEach((row, index) => {

				results[row] = _row[row]

				if ((Object.keys(_dataObj).length - 1) === index) {
					resolve(results)
				}

			});
		})

	}


	render() {

		const { objectlist, totalSize, page, limit, loading, bus, line } = this.state
		return (

			<div>
				<Row gutter={16}>
					<Col span={24}>
						<h2>ตั้งค่าสายเดินรถ</h2>
					</Col>
				</Row>
				<Row gutter={16} style={{ backgroundColor: "#FFEEC7" }}>
					<Col span={24}>
						<h5 style={{color:"red"}}>หมายเหตุ</h5>
					</Col>
					<Col span={6}>
						<p><b>การแก้ไขรถ</b></p>
						<p>กรอก <b>73</b> หากต้องการเปลี่ยนเป็นรถ <b>เบอร์ 1</b></p>
						<p>กรอก <b>74</b> หากต้องการเปลี่ยนเป็นรถ <b>เบอร์ 2</b></p>
						<p>กรอก <b>76</b> หากต้องการเปลี่ยนเป็นรถ <b>เบอร์ 3</b></p>
						<p>กรอก <b>77</b> หากต้องการเปลี่ยนเป็นรถ <b>เบอร์ 4</b></p>
					</Col>
					<Col span={6}>
						<p> </p><br />
						<p>กรอก <b>80</b> หากต้องการเปลี่ยนเป็นรถ <b>เบอร์ 5</b></p>
						<p>กรอก <b>81</b> หากต้องการเปลี่ยนเป็นรถ <b>เบอร์ 6</b></p>
						<p>กรอก <b>83</b> หากต้องการเปลี่ยนเป็นรถ <b>เบอร์ 7</b></p>
						<p>กรอก <b>84</b> หากต้องการเปลี่ยนเป็นรถ <b>เบอร์ 8</b></p>
					</Col>
				</Row>
				<br />
				<Row gutter={16}>
					<Col span={24}>
						<ModalAdd dataFromParent={bus} dataline={line} callback={this.funcCallBackModalAdd} />
					</Col>
				</Row>
				<br />
				<Row gutter={16}>
					<Col span={24}>
						{
							(
								objectlist.length === 0 ?
									null
									:
									<div>
										<FormModal title="แก้ไข" onRef={refs => (this.myChildFormModal = refs)} callback={this.funcCallBackModalUpdate} callbackDel={this.funcCallbackDelete} />
									</div>
							)
						}
						<Spin spinning={loading} delay={300} tip="Loading...">
							<DataGrid callback={this.DataGridCallback} totalSize={totalSize} page={page} limit={limit} paginationCallback={this.PaginationCallback} columns={columns} dataList={objectlist} />
						</Spin>
					</Col>
				</Row>
			</div>

		);
	}
}

const mapStateToProps = state => {
	return { auth: state.auth }
}

const mapDispatchToProps = dispatch => {
	return {
		fetchData: (token) => {
			// dispatch(busAction.fetchBusData(token))
		},
		updateBusData: (dataObj) => {
			// console.log("updateBusData => ",dataObj)
			// console.log("busAction =>",busAction.fetchBusSuccess(dataObj))
			// dispatch(busAction.fetchBusAgain(dataObj))
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LineSetting);
