import React, { Component } from "react"
import { connect } from 'react-redux'
import { Row, Col,message } from 'antd'
import { Spin, Icon } from 'antd'
import { Button, Input } from 'antd'
import { isEmpty } from '../../lib/utils'
import Session, { getItem } from '../../lib/session'
import service from '../../services/signup'

class SignUp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {},
            valid_pass: null,
            valid_cpass: null,
            valid_submit: null,
            valid_email: null,
            valid_username: null
        }
    }

    funcAdd = (_dataObj) => {
		service.Signup(_dataObj).then((res, err) => {
			console.log("res => ", res)
			if (res.status) {
				message.success('เพิ่มข้อมูลสำเร็จ');
				this.myChildForAdd.handleCancel()

			} else {
                console.log(res.error)
                if(res.error.response.status == 500){
                    message.success('เพิ่มข้อมูลสำเร็จ');
                }else{
                    message.error('เกิดข้อผิดพลาดในการเพิ่มข้อมูล กรุณาลองใหม่อีกครั้งค่ะ');
                }
				
			}
		})
	}

    setData = (_key, _detail) => {
        let data = this.state.data;
        data[_key] = _detail;
        this.setState({ data: data })
    }

    checkPassword = (_key, _detail) => {
        if (_detail.target.value.length < 6) {
            this.setState({ valid_pass: "Password must be 6 or more." })
        } else {
            this.setState({ valid_pass: null })
            this.setData(_key, _detail.target.value)
        }
    }

    checkConfirmPassword = (_key, _detail) => {
        //console.log(this.state.data["password1"])
        if (_detail.target.value != this.state.data["password1"]) {
            this.setState({ valid_cpass: "Comfirm Password doesn't match Password." })
        } else {
            this.setState({ valid_cpass: null })
            this.setData(_key, _detail.target.value)
        }
    }

    checkEmail = (_key, _detail) => {
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (reg.test(_detail.target.value) === false) {
            this.setState({ valid_email: "Email incorrect format." })
        } else {
            this.setState({ valid_email: null })
            this.setData(_key, _detail.target.value)
        }
    }

    checkUsername = (_key, _detail) => {
            this.setData(_key, _detail.target.value)
    }

    render() {
        const { disabled, visible, data, valid_email,valid_pass,valid_cpass } = this.state;

        return (
            <div>
                <Row gutter={16}>
                    <Col span={24}>
                        <h2>สมัครสมาชิก</h2>
                    </Col>
                </Row>
                <br />

                <Row gutter={16}>
                    <Col span={5}>
                        <p>Email</p>
                    </Col>
                    <Col span={7}>
                        <Input placeholder="Email" onChange={(text) => this.checkEmail("email", text)} />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <p style={{color:"red"}}>{valid_email}</p>
                </Row>
                <Row gutter={16}>
                    <Col span={5}>
                        <p>Username</p>
                    </Col>
                    <Col span={7}>
                        <Input placeholder="Username" onChange={(text) => this.checkUsername("username", text)} />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <p style={{color:"red"}}></p>
                </Row>
                <Row gutter={16}>
                    <Col span={5}>
                        <p>Password</p>
                    </Col>
                    <Col span={7}>
                        <Input placeholder="Password" type="password" onChange={(text) => this.checkPassword("password1", text)} />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <p style={{color:"red"}}>{valid_pass}</p>
                </Row>
                <Row gutter={16}>
                    <Col span={5}>
                        <p>Comfirm Password</p>
                    </Col>
                    <Col span={7}>
                        <Input placeholder="Comfirm Password" type="password" onChange={(text) => this.checkConfirmPassword("password2", text)} />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <p style={{color:"red"}}>{valid_cpass}</p>
                </Row>

                <Row gutter={16}>
                    <Col span={24}>
                        <Button type="primary" onClick={()=>this.funcAdd(this.state.data)}>บันทึก</Button>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return { auth: state.auth }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchData: (token) => {
            // dispatch(busAction.fetchBusData(token))
        },
        updateBusData: (dataObj) => {
            // console.log("updateBusData => ",dataObj)
            // console.log("busAction =>",busAction.fetchBusSuccess(dataObj))
            // dispatch(busAction.fetchBusAgain(dataObj))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);