import React, { Component } from "react"
import { connect } from 'react-redux'
import { Row, Col } from 'antd'
import { Spin, Rate, List } from 'antd'
import { Button, Select, Icon } from 'antd'
import { isEmpty } from '../../lib/utils'
import service from '../../services/rating'
import Session, { getItem } from '../../lib/session'
import { data } from "jquery"
import ExportExcel from '../../components/exportExcel'

const columns = [
    {
        dataField: 'title',
        text: 'หัวข้อ',
    },
    {
        dataField: 'score',
        text: 'คะแนน',
    },
    {
        dataField: 'from',
        text: 'จากจำนวน',
    },
];

class Ratings extends Component {

    constructor(props) {
        super(props)
        this.state = {
            data_avg: [],
            data_cavg: [],
            data_sgt: [],
            loading: true,
            dataexport: []
        }
    }

    componentDidMount() {
        if (isEmpty(getItem(Session.header))) {
            this.props.history.push('/login')
        } else {
            setTimeout(() => {
                if (this.props.auth.token !== null) {
                    this.init_data(this.props.auth.token)
                    this.init_data2(this.props.auth.token)
                    this.init_data3(this.props.auth.token)
                }
            }, 800)

        }

    }

    init_data = (_token) => {
        this.spinLoading(true)
        this.init_data_option(this.props.auth.token).then((res, err) => {
            if (res.state) {
                this.setState({
                    data_avg: res.data.data.results,
                })
                setTimeout(() => {
                    this.data_for_Excel()
                    this.spinLoading(false)
                }, 3000);
            } else {
                console.log("get data error !!")
            }

        })

    }

    init_data_option = (_token, _option) => {
        return new Promise((resolve, reject) => {
            service.getListAVG(_token, _option).then((res, err) => {
                if (res.status) {
                    if (res.data.status === 200) {
                        resolve({ state: true, data: res.data })
                    }
                } else {

                }

            })
        })
    }

    init_data2 = (_token) => {
        this.init_data_option2(this.props.auth.token).then((res, err) => {
            if (res.state) {
                this.setState({
                    data_sgt: res.data.data.results,
                })
            } else {
                console.log("get data error !!")
            }

        })
    }

    init_data_option2 = (_token, _option) => {
        return new Promise((resolve, reject) => {
            service.getListSGT(_token, _option).then((res, err) => {
                if (res.status) {
                    if (res.data.status === 200) {
                        resolve({ state: true, data: res.data })
                    }
                } else {

                }

            })
        })
    }

    init_data3 = (_token) => {
        this.init_data_option3(this.props.auth.token).then((res, err) => {
            if (res.state) {
                this.setState({
                    data_cavg: res.data.data.results,
                })
            } else {
                console.log("get data error !!")
            }

        })
    }

    init_data_option3 = (_token, _option) => {
        return new Promise((resolve, reject) => {
            service.getListCountAVG(_token, _option).then((res, err) => {
                if (res.status) {
                    if (res.data.status === 200) {
                        resolve({ state: true, data: res.data })
                    }
                } else {

                }

            })
        })
    }

    data_for_Excel = () => {
        var test = []
        if (this.state.data_cavg.length > 0) {
            this.state.data_cavg.map(x => {
                if (x.question_id === 19) {
                    test.push({
                        'title': 'ข้อมูลทั่วไป',
                        'score': x.answer === 'M' ? 'ชาย' : 'หญิง',
                        'from': x.total
                    })
                }
                if (x.question_id === 20) {
                    test.push({
                        'title': 'สถานภาพ',
                        'score': x.answer === 'student' ? 'นักศึกษา' : x.answer === 'teacher' ? 'อาจารย์' : x.answer === 'manager' ? 'ผู้บริหาร' : 'เจ้าหน้าที่',
                        'from': x.total
                    })
                }
                if (x.question_id === 21) {
                    test.push({
                        'title': 'สังกัด',
                        'score': x.answer === 'office' ? 'ส่วนงาน' : x.answer === 'university' ? 'สำนักวิชา' : 'หน่วยงานอื่น ๆ',
                        'from': x.total
                    })
                }
            })
        }
        if (this.state.data_avg.length > 0) {
            this.state.data_avg.map(x => {
                if (x.question_id === 1) {
                    test.push({
                        'title': 'คนขับ',
                        'score': x.avg,
                        'from': x.total
                    })
                }
                if (x.question_id === 2) {
                    test.push({
                        'title': 'รถเบอร์ 1',
                        'score': x.avg,
                        'from': x.total
                    })
                }
                if (x.question_id === 3) {
                    test.push({
                        'title': 'รถเบอร์ 2',
                        'score': x.avg,
                        'from': x.total
                    })
                }
                if (x.question_id === 4) {
                    test.push({
                        'title': 'รถเบอร์ 3',
                        'score': x.avg,
                        'from': x.total
                    })
                }
                if (x.question_id === 5) {
                    test.push({
                        'title': 'รถเบอร์ 4',
                        'score': x.avg,
                        'from': x.total
                    })
                }
                if (x.question_id === 6) {
                    test.push({
                        'title': 'รถเบอร์ 5',
                        'score': x.avg,
                        'from': x.total
                    })
                }
                if (x.question_id === 7) {
                    test.push({
                        'title': 'รถเบอร์ 6',
                        'score': x.avg,
                        'from': x.total
                    })
                }
                if (x.question_id === 8) {
                    test.push({
                        'title': 'รถเบอร์ 7',
                        'score': x.avg,
                        'from': x.total
                    })
                }
                if (x.question_id === 9) {
                    test.push({
                        'title': 'รถเบอร์ 8',
                        'score': x.avg,
                        'from': x.total
                    })
                }
                if (x.question_id === 10) {
                    test.push({
                        'title': 'แอปพลิเคชัน',
                        'score': x.avg,
                        'from': x.total
                    })
                }
                if (x.question_id === 11) {
                    test.push({
                        'title': 'สายสีแดง',
                        'score': x.avg,
                        'from': x.total
                    })
                }
                if (x.question_id === 12) {
                    test.push({
                        'title': 'สายสีเหลือง',
                        'score': x.avg,
                        'from': x.total
                    })
                }
                if (x.question_id === 13) {
                    test.push({
                        'title': 'สายสีเขียว',
                        'score': x.avg,
                        'from': x.total
                    })
                }
                if (x.question_id === 14) {
                    test.push({
                        'title': 'สายสีน้ำเงิน',
                        'score': x.avg,
                        'from': x.total
                    })
                }
                if (x.question_id === 15) {
                    test.push({
                        'title': 'สายสีม่วง',
                        'score': x.avg,
                        'from': x.total
                    })
                }
                if (x.question_id === 16) {
                    test.push({
                        'title': 'สายสีแสด',
                        'score': x.avg,
                        'from': x.total
                    })
                }
                if (x.question_id === 22) {
                    test.push({
                        'title': 'สภาพยานพาหนะ',
                        'score': x.avg,
                        'from': x.total
                    })
                }
                if (x.question_id === 23) {
                    test.push({
                        'title': 'ความสะอาดภายในยานพาหนะ',
                        'score': x.avg,
                        'from': x.total
                    })
                }
                if (x.question_id === 24) {
                    test.push({
                        'title': 'ระบบปรับอากาศในยานพาหนะ',
                        'score': x.avg,
                        'from': x.total
                    })
                }
                if (x.question_id === 25) {
                    test.push({
                        'title': 'การนัดหมายและความตรงต่อเวลา',
                        'score': x.avg,
                        'from': x.total
                    })
                }
                if (x.question_id === 26) {
                    test.push({
                        'title': 'การแต่งกายของพนักงานขับรถ',
                        'score': x.avg,
                        'from': x.total
                    })
                }
                if (x.question_id === 27) {
                    test.push({
                        'title': 'มารยาทในการขับขี่ของพนักงานขับรถ',
                        'score': x.avg,
                        'from': x.total
                    })
                }
                if (x.question_id === 28) {
                    test.push({
                        'title': 'การใช้วาจา มารยาทของพนักงานขับรถ',
                        'score': x.avg,
                        'from': x.total
                    })
                }
                if (x.question_id === 29) {
                    test.push({
                        'title': 'ความกระตือรือร้นในการให้บริการ',
                        'score': x.avg,
                        'from': x.total
                    })
                }
            })
        }
        if (this.state.data_sgt.length > 0) {
            this.state.data_sgt.map(x => {
                test.push({
                    'title': 'ข้อเสนอแนะ',
                    'score': x.answer,
                    'from': ''
                })
            })
        }
        if (test.length > 0) {
            this.setState({
                dataexport: test
            })
        }
    }


    spinLoading = (_bool) => {
        this.setState({ loading: _bool })
    }

    findScore = (id) => {
        if (this.state.data_avg.length > 0) {
            let obj = this.state.data_avg.find(x => x.question_id === id)
            if (obj === undefined || obj === null) {
                return 0
            } else {
                return obj.avg
            }
        } else {
            return 0;
        }
    }

    findTotal = (id) => {
        if (this.state.data_avg.length > 0) {
            let obj = this.state.data_avg.find(x => x.question_id === id)
            if (obj === undefined || obj === null) {
                return 0
            } else {
                return obj.total
            }
        } else {
            return 0;
        }
    }

    findObj = (id, val) => {
        if (this.state.data_cavg.length > 0) {
            let obj = this.state.data_cavg.find(x => x.question_id === id && x.answer === val)
            if (obj === undefined || obj === null) {
                return null
            } else {
                return obj
            }
        } else {
            return null;
        }
    }



    render() {
        const { loading, data_cavg, data_sgt, dataexport } = this.state
        return (

            <div>
                <Row gutter={16}>
                    <Col span={24}>
                        <h2>รายงานจากแบบสอบถามความพึงพอใจ</h2>
                    </Col>
                </Row>
                <Spin spinning={loading} delay={300} tip="Loading...">
                    <Row>
                        <Col span={20}>
                            <ExportExcel column={columns} data={dataexport} />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col span={9}>
                            <div style={styles.box_style}>
                                <Row>
                                    <div style={{ fontSize: 20, paddingTop: 15 }}>ข้อมูลทั่วไป</div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ fontSize: 16, width: '80%' }}>ชาย</div>
                                        <div style={{ fontSize: 16, width: '20%', textAlign: 'center' }}>{this.findObj(19, 'M') ? this.findObj(19, 'M').total : 0} คน</div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ fontSize: 16, width: '80%' }}>หญิง</div>
                                        <div style={{ fontSize: 16, width: '20%', textAlign: 'center' }}>{this.findObj(19, 'F') ? this.findObj(19, 'F').total : 0} คน</div>
                                    </div>
                                </Row>
                            </div>
                            <br />
                            <div style={styles.box_style}>
                                <Row>
                                    <div style={{ fontSize: 20, paddingTop: 15 }}>สถานภาพ</div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ fontSize: 16, width: '80%' }}>นักศึกษา</div>
                                        <div style={{ fontSize: 16, width: '20%', textAlign: 'center' }}>{this.findObj(20, 'student') ? this.findObj(20, 'student').total : 0} คน</div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ fontSize: 16, width: '80%' }}>อาจารย์</div>
                                        <div style={{ fontSize: 16, width: '20%', textAlign: 'center' }}>{this.findObj(20, 'teacher') ? this.findObj(20, 'teacher').total : 0} คน</div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ fontSize: 16, width: '80%' }}>ผู้บริหาร</div>
                                        <div style={{ fontSize: 16, width: '20%', textAlign: 'center' }}>{this.findObj(20, 'manager') ? this.findObj(20, 'manager').total : 0} คน</div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ fontSize: 16, width: '80%' }}>เจ้าหน้าที่</div>
                                        <div style={{ fontSize: 16, width: '20%', textAlign: 'center' }}>{this.findObj(20, 'employee') ? this.findObj(20, 'employee').total : 0} คน</div>
                                    </div>
                                </Row>
                            </div>
                            <br />
                            <div style={styles.box_style}>
                                <Row>
                                    <div style={{ fontSize: 20, paddingTop: 15 }}>สังกัด</div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ fontSize: 16, width: '80%' }}>ส่วนงาน</div>
                                        <div style={{ fontSize: 16, width: '20%', textAlign: 'center' }}>{this.findObj(21, 'office') ? this.findObj(21, 'office').total : 0} คน</div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ fontSize: 16, width: '80%' }}>สำนักวิชา</div>
                                        <div style={{ fontSize: 16, width: '20%', textAlign: 'center' }}>{this.findObj(21, 'university') ? this.findObj(21, 'university').total : 0} คน</div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ fontSize: 16, width: '80%' }}>หน่วยงานอื่น ๆ</div>
                                        <div style={{ fontSize: 16, width: '20%', textAlign: 'center' }}>{this.findObj(21, 'other') ? this.findObj(21, 'other').total : 0} คน</div>
                                    </div>
                                </Row>
                            </div>
                            <br />

                            <div style={styles.box_style}>
                                <div style={{ fontSize: 20, paddingTop: 15 }}>คนขับ</div>
                                <Row>
                                    <Col span={13}>
                                        <div style={{ fontSize: 24 }}>{this.findScore(1)}
                                            <span style={{ fontSize: 16 }}>/5</span>
                                        </div>
                                    </Col>
                                    <Col span={11} >
                                        <Rate
                                            allowHalf
                                            disabled
                                            style={styles.star}
                                            value={this.findScore(1)} />
                                        <div style={styles.people_text}>จาก {this.findTotal(1)} ข้อมูล</div>

                                    </Col>
                                </Row>

                            </div>
                            <br />
                            <div style={styles.box_style}>
                                <div style={{
                                    fontSize: 20,
                                    paddingTop: 15,
                                    borderBottomWidth: 1,
                                    borderBottomColor: "#D0D0D0",
                                    borderBottomStyle: "solid",
                                    marginRight: 10
                                }}>ตัวรถ</div>
                                <Row>
                                    <Col span={13}>
                                        <div style={{ fontSize: 16 }}>เบอร์ 1</div>
                                        <div style={{ fontSize: 24 }}>{this.findScore(2)}
                                            <span style={{ fontSize: 16 }}>/5</span>
                                        </div>
                                    </Col>
                                    <Col span={11} >
                                        <Rate
                                            allowHalf
                                            disabled
                                            style={styles.star2}
                                            value={this.findScore(2)} />
                                        <div style={styles.people_text}>จาก {this.findTotal(2)} ข้อมูล</div>

                                    </Col>
                                </Row>
                                <div style={styles.line}></div>
                                <Row>
                                    <Col span={13}>
                                        <div style={{ fontSize: 16 }}>เบอร์ 2</div>
                                        <div style={{ fontSize: 24 }}>{this.findScore(3)}
                                            <span style={{ fontSize: 16 }}>/5</span>
                                        </div>
                                    </Col>
                                    <Col span={11} >
                                        <Rate
                                            allowHalf
                                            disabled
                                            style={styles.star2}
                                            value={this.findScore(3)} />
                                        <div style={styles.people_text}>จาก {this.findTotal(3)} ข้อมูล</div>

                                    </Col>
                                </Row>
                                <div style={styles.line}></div>
                                <Row>
                                    <Col span={13}>
                                        <div style={{ fontSize: 16 }}>เบอร์ 3</div>
                                        <div style={{ fontSize: 24 }}>{this.findScore(4)}
                                            <span style={{ fontSize: 16 }}>/5</span>
                                        </div>
                                    </Col>
                                    <Col span={11} >
                                        <Rate
                                            allowHalf
                                            disabled
                                            style={styles.star2}
                                            value={this.findScore(4)} />
                                        <div style={styles.people_text}>จาก {this.findTotal(4)} ข้อมูล</div>

                                    </Col>
                                </Row>
                                <div style={styles.line}></div>
                                <Row>
                                    <Col span={13}>
                                        <div style={{ fontSize: 16 }}>เบอร์ 4</div>
                                        <div style={{ fontSize: 24 }}>{this.findScore(5)}
                                            <span style={{ fontSize: 16 }}>/5</span>
                                        </div>
                                    </Col>
                                    <Col span={11} >
                                        <Rate
                                            allowHalf
                                            disabled
                                            style={styles.star2}
                                            value={this.findScore(5)} />
                                        <div style={styles.people_text}>จาก {this.findTotal(5)} ข้อมูล</div>

                                    </Col>
                                </Row>
                                <div style={styles.line}></div>
                                <Row>
                                    <Col span={13}>
                                        <div style={{ fontSize: 16 }}>เบอร์ 5</div>
                                        <div style={{ fontSize: 24 }}>{this.findScore(6)}
                                            <span style={{ fontSize: 16 }}>/5</span>
                                        </div>
                                    </Col>
                                    <Col span={11} >
                                        <Rate
                                            allowHalf
                                            disabled
                                            style={styles.star2}
                                            value={this.findScore(6)} />
                                        <div style={styles.people_text}>จาก {this.findTotal(6)} ข้อมูล</div>

                                    </Col>
                                </Row>
                                <div style={styles.line}></div>
                                <Row>
                                    <Col span={13}>
                                        <div style={{ fontSize: 16 }}>เบอร์ 6</div>
                                        <div style={{ fontSize: 24 }}>{this.findScore(7)}
                                            <span style={{ fontSize: 16 }}>/5</span>
                                        </div>
                                    </Col>
                                    <Col span={11} >
                                        <Rate
                                            allowHalf
                                            disabled
                                            style={styles.star2}
                                            value={this.findScore(7)} />
                                        <div style={styles.people_text}>จาก {this.findTotal(7)} ข้อมูล</div>

                                    </Col>
                                </Row>
                                <div style={styles.line}></div>
                                <Row>
                                    <Col span={13}>
                                        <div style={{ fontSize: 16 }}>เบอร์ 7</div>
                                        <div style={{ fontSize: 24 }}>{this.findScore(8)}
                                            <span style={{ fontSize: 16 }}>/5</span>
                                        </div>
                                    </Col>
                                    <Col span={11} >
                                        <Rate
                                            allowHalf
                                            disabled
                                            style={styles.star2}
                                            value={this.findScore(8)} />
                                        <div style={styles.people_text}>จาก {this.findTotal(8)} ข้อมูล</div>

                                    </Col>
                                </Row>
                                <div style={styles.line}></div>
                                <Row>
                                    <Col span={13}>
                                        <div style={{ fontSize: 16 }}>เบอร์ 8</div>
                                        <div style={{ fontSize: 24 }}>{this.findScore(9)}
                                            <span style={{ fontSize: 16 }}>/5</span>
                                        </div>
                                    </Col>
                                    <Col span={11} >
                                        <Rate
                                            allowHalf
                                            disabled
                                            style={styles.star2}
                                            value={this.findScore(9)} />
                                        <div style={styles.people_text}>จาก {this.findTotal(9)} ข้อมูล</div>

                                    </Col>
                                </Row>
                            </div>
                            <br />
                            <div style={styles.box_style}>
                                <div style={{ fontSize: 20, paddingTop: 15 }}>แอปพลิเคชัน</div>
                                <Row>
                                    <Col span={13}>
                                        <div style={{ fontSize: 24 }}>{this.findScore(10)}
                                            <span style={{ fontSize: 16 }}>/5</span>
                                        </div>
                                    </Col>
                                    <Col span={11} >
                                        <Rate
                                            allowHalf
                                            disabled
                                            style={styles.star}
                                            value={this.findScore(10)} />
                                        <div style={styles.people_text}>จาก {this.findTotal(10)} ข้อมูล</div>

                                    </Col>
                                </Row>
                            </div>
                            <br />
                            <div style={styles.box_style}>
                                <div style={{ fontSize: 20, paddingTop: 15 }}>ความกระตือรือร้นในการให้บริการ</div>
                                <Row>
                                    <Col span={13}>
                                        <div style={{ fontSize: 24 }}>{this.findScore(29)}
                                            <span style={{ fontSize: 16 }}>/5</span>
                                        </div>
                                    </Col>
                                    <Col span={11} >
                                        <Rate
                                            allowHalf
                                            disabled
                                            style={styles.star}
                                            value={this.findScore(29)} />
                                        <div style={styles.people_text}>จาก {this.findTotal(29)} ข้อมูล</div>

                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={9}>

                            <div style={{
                                borderColor: '#379683',
                                borderWidth: 2,
                                borderStyle: "solid",
                                paddingLeft: 10,
                                paddingBottom: 10
                            }}>
                                <div style={{
                                    fontSize: 20,
                                    paddingTop: 15,
                                    borderBottomWidth: 1,
                                    borderBottomColor: "#D0D0D0",
                                    borderBottomStyle: "solid",
                                    marginRight: 10
                                }}>ป้ายและจุดจอด</div>
                                <Row>
                                    <Col span={13}>
                                        <div style={{ fontSize: 16 }}>สายสีแดง</div>
                                        <div style={{ fontSize: 24 }}>{this.findScore(11)}
                                            <span style={{ fontSize: 16 }}>/5</span>
                                        </div>
                                    </Col>
                                    <Col span={11} >
                                        <Rate
                                            allowHalf
                                            disabled
                                            style={styles.star2}
                                            value={this.findScore(11)} />
                                        <div style={styles.people_text}>จาก {this.findTotal(11)} ข้อมูล</div>

                                    </Col>
                                </Row>
                                <div style={styles.line}></div>
                                <Row>
                                    <Col span={13}>
                                        <div style={{ fontSize: 16 }}>สายสีเหลือง</div>
                                        <div style={{ fontSize: 24 }}>{this.findScore(12)}
                                            <span style={{ fontSize: 16 }}>/5</span>
                                        </div>
                                    </Col>
                                    <Col span={11} >
                                        <Rate
                                            allowHalf
                                            disabled
                                            style={styles.star2}
                                            value={this.findScore(12)} />
                                        <div style={styles.people_text}>จาก {this.findTotal(12)} ข้อมูล</div>

                                    </Col>
                                </Row>
                                <div style={styles.line}></div>
                                <Row>
                                    <Col span={13}>
                                        <div style={{ fontSize: 16 }}>สายสีเขียว</div>
                                        <div style={{ fontSize: 24 }}>{this.findScore(13)}
                                            <span style={{ fontSize: 16 }}>/5</span>
                                        </div>
                                    </Col>
                                    <Col span={11} >
                                        <Rate
                                            allowHalf
                                            disabled
                                            style={styles.star2}
                                            value={this.findScore(13)} />
                                        <div style={styles.people_text}>จาก {this.findTotal(13)} ข้อมูล</div>

                                    </Col>
                                </Row>
                                <div style={styles.line}></div>
                                <Row>
                                    <Col span={13}>
                                        <div style={{ fontSize: 16 }}>สายสีน้ำเงิน</div>
                                        <div style={{ fontSize: 24 }}>{this.findScore(14)}
                                            <span style={{ fontSize: 16 }}>/5</span>
                                        </div>
                                    </Col>
                                    <Col span={11} >
                                        <Rate
                                            allowHalf
                                            disabled
                                            style={styles.star2}
                                            value={this.findScore(14)} />
                                        <div style={styles.people_text}>จาก {this.findTotal(14)} ข้อมูล</div>

                                    </Col>
                                </Row>
                                <div style={styles.line}></div>
                                <Row>
                                    <Col span={13}>
                                        <div style={{ fontSize: 16 }}>สายสีม่วง</div>
                                        <div style={{ fontSize: 24 }}>{this.findScore(15)}
                                            <span style={{ fontSize: 16 }}>/5</span>
                                        </div>
                                    </Col>
                                    <Col span={11} >
                                        <Rate
                                            allowHalf
                                            disabled
                                            style={styles.star2}
                                            value={this.findScore(15)} />
                                        <div style={styles.people_text}>จาก {this.findTotal(15)} ข้อมูล</div>

                                    </Col>
                                </Row>
                                <div style={styles.line}></div>
                                <Row>
                                    <Col span={13}>
                                        <div style={{ fontSize: 16 }}>สายสีแสด</div>
                                        <div style={{ fontSize: 24 }}>{this.findScore(16)}
                                            <span style={{ fontSize: 16 }}>/5</span>
                                        </div>
                                    </Col>
                                    <Col span={11} >
                                        <Rate
                                            allowHalf
                                            disabled
                                            style={styles.star2}
                                            value={this.findScore(16)} />
                                        <div style={styles.people_text}>จาก {this.findTotal(16)} ข้อมูล</div>

                                    </Col>
                                </Row>
                            </div>
                            <br />
                            <div style={styles.box_style}>
                                <div style={{ fontSize: 20, paddingTop: 15 }}>สภาพยานพาหนะ</div>
                                <Row>
                                    <Col span={13}>
                                        <div style={{ fontSize: 24 }}>{this.findScore(22)}
                                            <span style={{ fontSize: 16 }}>/5</span>
                                        </div>
                                    </Col>
                                    <Col span={11} >
                                        <Rate
                                            allowHalf
                                            disabled
                                            style={styles.star}
                                            value={this.findScore(22)} />
                                        <div style={styles.people_text}>จาก {this.findTotal(22)} ข้อมูล</div>

                                    </Col>
                                </Row>
                            </div>
                            <br />
                            <div style={styles.box_style}>
                                <div style={{ fontSize: 20, paddingTop: 15 }}>ความสะอาดภายในยานพาหนะ</div>
                                <Row>
                                    <Col span={13}>
                                        <div style={{ fontSize: 24 }}>{this.findScore(23)}
                                            <span style={{ fontSize: 16 }}>/5</span>
                                        </div>
                                    </Col>
                                    <Col span={11} >
                                        <Rate
                                            allowHalf
                                            disabled
                                            style={styles.star}
                                            value={this.findScore(23)} />
                                        <div style={styles.people_text}>จาก {this.findTotal(23)} ข้อมูล</div>

                                    </Col>
                                </Row>
                            </div>
                            <br />
                            <div style={styles.box_style}>
                                <div style={{ fontSize: 20, paddingTop: 15 }}>ระบบปรับอากาศในยานพาหนะ</div>
                                <Row>
                                    <Col span={13}>
                                        <div style={{ fontSize: 24 }}>{this.findScore(24)}
                                            <span style={{ fontSize: 16 }}>/5</span>
                                        </div>
                                    </Col>
                                    <Col span={11} >
                                        <Rate
                                            allowHalf
                                            disabled
                                            style={styles.star}
                                            value={this.findScore(24)} />
                                        <div style={styles.people_text}>จาก {this.findTotal(24)} ข้อมูล</div>

                                    </Col>
                                </Row>
                            </div>
                            <br />
                            <div style={styles.box_style}>
                                <div style={{ fontSize: 20, paddingTop: 15 }}>การนัดหมายและความตรงต่อเวลา</div>
                                <Row>
                                    <Col span={13}>
                                        <div style={{ fontSize: 24 }}>{this.findScore(25)}
                                            <span style={{ fontSize: 16 }}>/5</span>
                                        </div>
                                    </Col>
                                    <Col span={11} >
                                        <Rate
                                            allowHalf
                                            disabled
                                            style={styles.star}
                                            value={this.findScore(25)} />
                                        <div style={styles.people_text}>จาก {this.findTotal(25)} ข้อมูล</div>

                                    </Col>
                                </Row>
                            </div>
                            <br />
                            <div style={styles.box_style}>
                                <div style={{ fontSize: 20, paddingTop: 15 }}>การแต่งกายของพนักงานขับรถ</div>
                                <Row>
                                    <Col span={13}>
                                        <div style={{ fontSize: 24 }}>{this.findScore(26)}
                                            <span style={{ fontSize: 16 }}>/5</span>
                                        </div>
                                    </Col>
                                    <Col span={11} >
                                        <Rate
                                            allowHalf
                                            disabled
                                            style={styles.star}
                                            value={this.findScore(26)} />
                                        <div style={styles.people_text}>จาก {this.findTotal(26)} ข้อมูล</div>

                                    </Col>
                                </Row>
                            </div>
                            <br />
                            <div style={styles.box_style}>
                                <div style={{ fontSize: 20, paddingTop: 15 }}>มารยาทในการขับขี่ของพนักงานขับรถ</div>
                                <Row>
                                    <Col span={13}>
                                        <div style={{ fontSize: 24 }}>{this.findScore(27)}
                                            <span style={{ fontSize: 16 }}>/5</span>
                                        </div>
                                    </Col>
                                    <Col span={11} >
                                        <Rate
                                            allowHalf
                                            disabled
                                            style={styles.star}
                                            value={this.findScore(27)} />
                                        <div style={styles.people_text}>จาก {this.findTotal(27)} ข้อมูล</div>

                                    </Col>
                                </Row>
                            </div>
                            <br />
                            <div style={styles.box_style}>
                                <div style={{ fontSize: 20, paddingTop: 15 }}>การใช้วาจา มารยาทของพนักงานขับรถ</div>
                                <Row>
                                    <Col span={13}>
                                        <div style={{ fontSize: 24 }}>{this.findScore(28)}
                                            <span style={{ fontSize: 16 }}>/5</span>
                                        </div>
                                    </Col>
                                    <Col span={11} >
                                        <Rate
                                            allowHalf
                                            disabled
                                            style={styles.star}
                                            value={this.findScore(28)} />
                                        <div style={styles.people_text}>จาก {this.findTotal(28)} ข้อมูล</div>

                                    </Col>
                                </Row>
                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div style={{ fontSize: 20, paddingTop: 20, }}>
                                ข้อเสนอแนะ
                        </div>
                            <div style={styles.line}></div>
                            {
                                data_sgt.length > 0 ?
                                    <List
                                        dataSource={data_sgt}
                                        renderItem={item => (
                                            <List.Item>
                                                {'-  ' + item.answer}
                                            </List.Item>
                                        )}
                                    />
                                    :
                                    <span>ไม่พบข้อเสนอแนะ</span>
                            }
                        </Col>
                    </Row>
                </Spin>
            </div>

        );
    }
}

const mapStateToProps = state => {
    return { auth: state.auth }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchData: (token) => {
            // dispatch(busAction.fetchBusData(token))
        },
        updateBusData: (dataObj) => {
            // console.log("updateBusData => ",dataObj)
            // console.log("busAction =>",busAction.fetchBusSuccess(dataObj))
            // dispatch(busAction.fetchBusAgain(dataObj))
        }
    }
}

const styles = {
    box_style: {
        borderColor: '#379683',
        borderWidth: 2,
        borderStyle: "solid",
        paddingLeft: 10,
        paddingBottom: 10
    },
    line: {
        borderBottomWidth: 1,
        borderBottomColor: "#D0D0D0",
        borderBottomStyle: "solid",
        marginRight: 10,
        marginTop: 3,
        marginBottom: 3
    },
    star: {
        // paddingTop: 20,
        paddingLeft: 20
    },
    star2: {
        paddingTop: 5,
        paddingLeft: 20
    },
    people_text: {
        textAlign: 'right',
        paddingRight: 10,
        fontSize: 14
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Ratings);
