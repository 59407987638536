import React, { Component } from "react"
import {
    Button
    , Modal
    , Form
    , Input
    , TimePicker
    , AutoComplete
    , Row, Col
} from 'antd';
const { Option } = AutoComplete;

const CollectionCreateForm = Form.create({ name: 'form_in_modal' })(

    class extends React.Component {

        render() {
            const { visible, onCancel, onCreate, form, dataSource, onChangeTime, dataLine, onchangeAuto } = this.props;
            const { getFieldDecorator } = form;
            const formItemLayout = {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 8 },
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 16 },
                },
            };
            const options1 = dataSource.map(item => (
                <Option value={item.bus_number} key={item.id}>
                    <span>{item.bus_number}</span>
                </Option>
            ))
            const options2 = dataLine.map(item1 => (
                <Option value={item1.name} key={item1.id}>
                    <span>{item1.name}</span>
                </Option>
            ))

            return (
                <Modal
                    width={860}
                    visible={visible}
                    footer={false}
                    title="เพื่มการตั้งค่า"
                    onCancel={onCancel}
                >
                    <div style={{ marginTop: 10, marginBottom: 10 }}>
                        <Form {...formItemLayout}>
                            <Form.Item label="เบอร์รถ">
                                {getFieldDecorator('bus',)(<AutoComplete
                                    style={{ width: 300 }}
                                    dataSource={options1}
                                    placeholder="เบอร์รถ"
                                    optionLabelProp="value"
                                    onChange={(event, options) => { onchangeAuto(event, "bus", options.key) }}
                                />)}
                            </Form.Item>
                            <Form.Item label="สายเดินรถ">
                                {getFieldDecorator('line',)(<AutoComplete
                                    style={{ width: 300 }}
                                    dataSource={options2}
                                    placeholder="สายเดินรถ"
                                    optionLabelProp="value"
                                    onChange={(event, options) => { onchangeAuto(event, "line", options.key) }}
                                />)}
                            </Form.Item>
                            <Form.Item label="เวลาที่รถเริ่มวิ่ง">
                                {getFieldDecorator('start_time')(<TimePicker format="HH:mm" style={{ width: 300 }} onChange={(time, timeString) => { onChangeTime(timeString, "start_time") }} />)}
                            </Form.Item>
                            <Form.Item label="เวลาที่รถหยุดวิ่ง">
                                {getFieldDecorator('end_time')(<TimePicker format="HH:mm" style={{ width: 300 }} onChange={(time, timeString) => { onChangeTime(timeString, "end_time") }} />)}
                            </Form.Item>
                        </Form>
                        <Row gutter={32} style={{ marginTop: 15, marginBottom: 15 }}>
                            <Col style={{ textAlign: 'right' }} xs={{ span: 16, offset: 4 }} >
                                <div style={{ marginRight: 20 }}>
                                    <Button style={{ width: 100 }} type="danger" onClick={onCancel} >ยกเลิก</Button>
                                    {'  '}
                                    <Button style={{ width: 100 }} onClick={onCreate} type="primary">บันทึก</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Modal>
            );
        }
    },
);

class ModalAdd extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            dataFromParent: props.dataFromParent,
            dataline: props.dataline,
            visible: false,
            dataInForm: {}
        }
    }

    showModal = () => {
        this.setState({ visible: true });
    };

    handleCancel = () => {
        this.setState({ visible: false });
    };

    handleCreate = () => {
        setTimeout(() => {
            this.props.callback(this.state.dataInForm)
        }, 1000)
    };

    saveFormRef = formRef => {
        this.formRef = formRef;
    };

    onChangeTime = (time, key) => {
        let dataObj = this.state.dataInForm
        let dateStr = time.toString();
        dataObj[key] = dateStr
        this.setState({dataInForm:dataObj})
        console.log(this.state.dataInForm)
    }

    onchangeInput = (_event, _key) => {
        let data = this.state.dataInForm
        data[_key] = (parseInt(_event.target.value))
        this.setState({ dataInForm: data })
    }

    onchangeAuto = (_event, _key, _id) => {
        let data = this.state.dataInForm
        data[_key] = (_id)
        this.setState({ dataInForm: data })
        console.log(this.state.dataInForm)
    }

    render() {
        return (
            <div>
                <Button type="primary" style={{ float: "right" }} onClick={this.showModal}>
                    เพิ่มรายการ
        </Button>
                <CollectionCreateForm
                    wrappedComponentRef={this.saveFormRef}
                    visible={this.state.visible}
                    onCancel={this.handleCancel}
                    onCreate={this.handleCreate}
                    dataSource={this.props.dataFromParent}
                    dataLine={this.props.dataline}
                    onChangeTime={this.onChangeTime}
                    onchangeInput={this.onchangeInput}
                    onchangeAuto={this.onchangeAuto}
                />
            </div>
        );
    }
}
export default ModalAdd;
