
const dataSchema = {
    required:[],
    dataStructure:{
        id:null,
        title: null,
        description: null,
        post_date: null,
        image: null
    },
    properties:{
            id: {
                key:"id",
                type: "integer",
                label: "ID"
            },
            title: {
                key:"title",
                type: "string",
                label: "หัวข้อ",
                max_length: 50
            },
            description: {
                key:"description",
                type: "string",
                label: "รายละเอียด",
                max_length: 400
            },
            post_date: {
                key:"post_date",
                type: "datetime",
                label: "วันที่ประกาศ"
            },
            image: {
                key:"image",
                type: "image upload",
                label: "Image"
            }
    }
}


export const schema = dataSchema