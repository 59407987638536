import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from 'antd'
import { Spin } from "antd";
import { isEmpty } from "../../lib/utils";
import Session, { getItem } from "../../lib/session";
import DataGrid from "../../components/dataGrid";
import axios from "axios";
class BusTimeTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            objectlist: [],
            limit: 20,
            offset: 0,
            filter_string: "",
            totalSize: 0,
            sizePerPage: 20,
            page: 1,
            loading: true,
            reportType: "",
            html_res: null
        };
    }
    getDataTimeTable = async () => {
        await axios.get('https://api.yunai.asia/bus_time_table/5/19/', {
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization':('Token '+_token),
                'apikey': "ThJDtsJpbKpY8ogTH9RS3DiCd5qmS9BH",
            },
            // responseType: 'document'

        }).then((res) => {
            this.state.html_res = res.data
            // this.state.html_res = res.config.url
            console.log(res)
        }).catch((err) => { console.log(err) })
    }
    genHTML = (data) => {
        return (
            <div>
                <div dangerouslySetInnerHTML={{ __html: data }} />
            </div>
        )
    }
    componentDidMount() {
        // this.getDataTimeTable()
    }

    render() {
        return (
            <div>
                <Row gutter={16}>
                    <Col span={24}>
                        <h3>ตารางเวลาเดินรถ</h3>
                        {/* <iframe src="https://api.yunai.asia/bus_time_table/5/15/"
                        /> */}
                        <a href="https://api.yunai.asia/bus_time_table/5/15/" target="_blank">ดูตารางเวลาเดินรถ</a>
                        {/* {this.state.html_res ? this.genHTML(this.state.html_res) : <></>} */}
                    </Col>
                </Row>
            </div>
        );
    }
}

export default (BusTimeTable);
