
const dataSchema = {
    required:[],
    dataStructure:{
        id:null,
        bus: null,
        line: null,
        start_time: null,
        end_time: null
    },
    properties:{
            id: {
                key:"id",
                type: "integer",
                label: "ID"
            },
            bus: {
                key:"bus",
                type: "integer",
                label: "เบอร์รถ",
            },
            line: {
                key:"line",
                type: "autocomplete",
                label: "สายเดินรถ",
            },
            start_time: {
                key:"start_time",
                type: "string",
                label: "เวลาที่เริ่มวิ่ง"
            },
            end_time: {
                key:"end_time",
                type: "string",
                label: "เวลาที่หยุดวิ่ง"
            }
    }
}


export const schema = dataSchema